import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Badge from '../components/Badge'
import Button from '../components/Button'
import Card from '../components/Card'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import airbusImage from '../assets/images/projects/airbus.webp'
import dribbbleIcon from '../assets/icons/dribbble.svg'
import instagramIcon from '../assets/icons/instagram.svg'
import linkedinIcon from '../assets/icons/linkedin.svg'
import nebuleuseImage from '../assets/images/projects/design-system__nebuleuse.webp'
import shapeOne from '../assets/images/home-background.webp'
import vrticeImage from '../assets/images/projects/application-saas__vrtice.webp'

export default function IndexPage() {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    window.scrollTo(0, 0)

    setIsLoading(false)
  }, [])

  return (
    <main className=" bg-black text-white">
      <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
        <title>Ksatra SEM | Fullstack designer freelance à Toulouse</title>
        <meta
          name="description"
          content="Je vous accompagne dans le design UX/UI et le développement front-end de vos projets numériques : site vitrine, application web, ux/ui, design system, designops."
        />

        <meta property="og:url" content="https://ksatra.fr/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Ksatra SEM | Fullstack designer freelance à Toulouse"
        />
        <meta
          property="og:description"
          content="Je vous accompagne dans le design UX/UI et le développement front-end de vos projets numériques : site vitrine, application web, ux/ui, design system, designops."
        />
        <meta
          property="og:image"
          content="https://drive.google.com/uc?id=1XY2l8P2sZVsw_HcAz49mG6XHg9qt865c"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="ksatra.fr" />
        <meta property="twitter:url" content="https://ksatra.fr/" />
        <meta
          name="twitter:title"
          content="Ksatra SEM | Fullstack designer freelance à Toulouse"
        />
        <meta
          name="twitter:description"
          content="Je vous accompagne dans le design UX/UI et le développement front-end de vos projets numériques : site vitrine, application web, ux/ui, design system, designops."
        />
        <meta
          name="twitter:image"
          content="https://drive.google.com/uc?id=1XY2l8P2sZVsw_HcAz49mG6XHg9qt865c"
        />
      </Helmet>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <img
        src={shapeOne}
        alt="Image d'arrière-plan représentant des artefacts dégardés"
        className="background-home__shape-1 !absolute w-screen"
      />

      {/* Jumbo */}
      <div className="px-6 lg:px-32">
        <div className="pt-16 lg:pt-24">
          <div className="">
            <Fade bottom>
              <h1 className="font-serif text-5xl lg:text-7xl">
                Fullstack designer <br /> freelance
              </h1>
            </Fade>
            <Fade bottom delay={800}>
              <h2 className="pt-8 text-lg text-gray-200 lg:w-1/2 lg:text-xl">
                👋 Je vous accompagne dans la{' '}
                <span className="font-serif text-white">conception UX/UI </span>
                et le
                <span className="font-serif text-white">
                  {' '}
                  développement front-end
                </span>{' '}
                de vos projets numériques.
              </h2>
              <Badge title="Basé à Toulouse, France" className="mt-8" />
              <div className="mt-8 flex gap-4">
                <a href="https://www.linkedin.com/in/ksatra/" target="blank">
                  <img src={linkedinIcon} alt="logo linkedin"></img>
                </a>
                <a href="https://www.instagram.com/ksatra_s/" target="blank">
                  <img src={instagramIcon} alt="logo instagram"></img>
                </a>
                <a href="https://dribbble.com/ksatra" target="blank">
                  <img src={dribbbleIcon} alt="logo dribbble"></img>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="mt-32 flex flex-col gap-32 lg:mt-64">
        {/* Design */}
        <div className="grid pl-6 md:grid-cols-4 lg:px-32">
          <div className="col-span-4 col-start-1 mb-20 lg:mb-24">
            <Fade bottom>
              <h3 className="mb-4 flex flex-col font-serif text-5xl text-white lg:text-6xl">
                Design.
              </h3>
              <p className="w-2/3 text-lg text-gray-200 md:w-1/2 lg:pt-6 lg:text-xl">
                Concevoir des interfaces{' '}
                <span className="font-serif text-white">marquantes</span>,
                <span className="font-serif text-white"> mémorables</span> et
                des expériences utilisateurs{' '}
                <span className="font-serif text-white">significatives.</span>
              </p>
            </Fade>
          </div>
          <div className="col-start-1">
            <Fade bottom>
              <div className="flex flex-col">
                <div className="pb-8 text-gray-200">Expertises</div>
                <div className="flex flex-row">
                  <div className="flex flex-col gap-8">
                    <p className="font-serif text-2xl lg:text-4xl">UX/UI</p>
                    <p className="font-serif text-2xl lg:text-4xl">
                      Design System
                    </p>
                    <p className="mb-8 font-serif text-2xl lg:px-0 lg:text-4xl">
                      DesignOps
                    </p>
                    <Link to="/design">
                      <Button title="En savoir plus" />
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <div className="col-span-2 col-start-3 hidden md:flex">
              <StaticImage
                src="../assets/images/design.webp"
                alt="Aperçu de la section design"
                placeholder="blurred"
                className="h-fit rounded-lg"
              />
            </div>
          </Fade>
        </div>

        {/* Code */}
        <div className="grid pl-6 md:grid-cols-4 lg:px-32">
          <div className="col-span-4 col-start-1 mb-20 lg:mb-24">
            <Fade bottom>
              <h3 className="mb-4 flex flex-col font-serif text-5xl text-white lg:text-6xl">
                Code.
              </h3>
              <p className="w-2/3 text-lg text-gray-200 md:w-1/2 lg:pt-6 lg:text-xl">
                Construire des projets{' '}
                <span className="font-serif text-white">durables</span> avec des
                technologies{' '}
                <span className="font-serif text-white">modernes</span> et{' '}
                <span className="font-serif text-white">fiables.</span>
              </p>
            </Fade>
          </div>
          <div className="col-start-1">
            <Fade bottom>
              <div className="flex flex-col">
                <div className="pb-8 text-gray-200">Expertises</div>
                <div className="flex flex-row">
                  <div className="flex flex-col gap-8">
                    <p className="font-serif text-2xl lg:text-4xl">
                      Application web
                    </p>
                    <p className="mb-8 font-serif text-2xl lg:text-4xl">
                      Site vitrine
                    </p>
                    <Link to="/code">
                      <Button title="En savoir plus" />
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <div className="col-span-2 col-start-3 hidden md:flex ">
              <StaticImage
                src="../assets/images/code.webp"
                alt="Aperçu de la section code"
                placeholder="blurred"
                className="h-fit rounded-lg"
              />
            </div>
          </Fade>
        </div>

        {/* Play */}
        <div className="flex flex-col">
          <div className="mb-20 flex flex-col px-6 pt-8 text-left lg:mb-24 lg:px-32">
            <Fade bottom>
              <h3 className="mb-4 font-serif text-5xl text-white lg:text-6xl">
                Play.
              </h3>
              <p className="gray-200 w-2/3 text-lg md:w-1/2 lg:text-xl">
                Découvrez quelques projets de refonte{' '}
                <span className="font-serif text-white">site web</span>,
                conception{' '}
                <span className="font-serif text-white">
                  d'application web/mobile
                </span>
                , et de{' '}
                <span className="font-serif text-white">design system</span>.
              </p>
            </Fade>
          </div>

          <div className="flex flex-col">
            <div className="grid gap-20 px-6 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-12 lg:px-32">
              <Card
                description="Conception et développement de la solution Data Product Management"
                expertises={['UX/UI Design', 'React']}
                media={airbusImage}
                mediaAlt="Aperçu projet Airbus"
                privateProject
                title="Airbus"
              />

              <Card
                description="Construction d'un Design System sous Figma"
                expertises={['Design System', 'DesignOps']}
                link="https://dribbble.com/shots/19417125-N-buleuse-Design-System"
                media={nebuleuseImage}
                mediaAlt="Aperçu Design System de Nébuleuse"
                title="Nébuleuse Design System"
              />

              <Card
                description="Conception UX/UI de l'application web"
                expertises={['UX/UI Design', 'Design Sprint']}
                link="https://vrtice.com/"
                media={vrticeImage}
                mediaAlt="Aperçu application web de Vrtice"
                subtitle="@lachouetteco"
                title="Vrtice"
              />
            </div>

            <div className="mt-16 flex justify-center text-center md:mt-16">
              <Link to="/play">
                <Button title="Découvrir d'autres projets" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Fade bottom>
        <Footer />
      </Fade>
    </main>
  )
}
